<template>
    <div class="board-container">
        <report-list-categories v-if="code == 'report'" />
        <table class="board-list">
    		<thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:10%;" class="board-list__txt d-none d-lg-table-cell">{{$t('common.number')}}</th>
                    <th v-if="code == 'report'" style="width:20%;" class="board-list__tit">{{$t('common.카테고리')}}</th>
                    <th style="width:50%;" class="board-list__tit">{{$t('common.제목')}}</th>
                    <th style="width:15%;" class="board-list__txt">{{$t('common.작성자')}}</th>
                    <th style="width:15%;" class="board-list__txt">{{$t('common.작성일')}}</th>
                    <th style="width:10%;" class="board-list__txt">{{$t('common.조회수')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notice, index) in notices" :key="`notice-${index}`" @click="showBoard(notice)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                    </td>
                    <td v-if="code == 'report'" align="center" class="board-list__txt d-none d-lg-table-cell">
                        <div class="ellip">
                            <span>{{ categories[notice.category]?.text || "" }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ notice.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ notice.writer.name }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notice.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notice.viewCount }}
                    </td>
                </tr>
                <tr v-for="(board, index) in boards" :key="`board-${index}`" @click="showBoard(board)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <span>{{ summary.totalCount - skip - index }}</span>
                    </td>
                    <td v-if="code == 'report'" align="center" class="board-list__txt d-none d-lg-table-cell">
                        <div class="ellip">
                            <span>{{ categories[board.category]?.text || "" }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ board.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ board.writer.name }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ board.viewCount }}
                    </td>
                </tr>
    		</tbody>
    	</table>

        <pagination-component :count="count" :value="page" @change="changePage" />

        <div class="mt-20 mt-md-30">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn @click="$emit('loadmore')" large color="secondary">{{$t("common.list")}}</v-btn>
                <v-btn @click="$router.push('?mode=input')" large color="primary">{{$t("common.writing")}}</v-btn>
            </div>
            <div class="v-btn--group justify-end">
                <v-btn @click="$router.push(`${$route.path}/write`)" large color="primary">{{$t("common.writing")}}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import PaginationComponent from "@/components/client/control/pagination-component.vue";
import ReportListCategories from './report-list-categories.vue';
import { BOARD_REPORT_CATEGORIES } from '@/assets/variables';

export default {
    components: {
        PaginationComponent,
        ReportListCategories,
    },

    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(board) {
            this.$router.push(`${this.$route.path}/${board._id}`);
        },
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
        categories() {
            if (this.code == "report") return BOARD_REPORT_CATEGORIES;
            return {}
        },
    },
};
</script>